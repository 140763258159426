import { default as indexfbLPq4CEzrMeta } from "/var/www/nuxt-v3/prod/releases/28/pages/index.vue?macro=true";
import { default as simulation_45pinelmeNadAxDPcMeta } from "/var/www/nuxt-v3/prod/releases/28/pages/simulateurs-immobilier/simulation-pinel.vue?macro=true";
import { default as simulation_45ptzzT8DMs1axkMeta } from "/var/www/nuxt-v3/prod/releases/28/pages/simulateurs-immobilier/simulation-ptz.vue?macro=true";
export default [
  {
    name: indexfbLPq4CEzrMeta?.name ?? "index",
    path: indexfbLPq4CEzrMeta?.path ?? "/",
    meta: indexfbLPq4CEzrMeta || {},
    alias: indexfbLPq4CEzrMeta?.alias || [],
    redirect: indexfbLPq4CEzrMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/28/pages/index.vue").then(m => m.default || m)
  },
  {
    name: simulation_45pinelmeNadAxDPcMeta?.name ?? "simulateurs-immobilier-simulation-pinel",
    path: simulation_45pinelmeNadAxDPcMeta?.path ?? "/simulateurs-immobilier/simulation-pinel",
    meta: simulation_45pinelmeNadAxDPcMeta || {},
    alias: simulation_45pinelmeNadAxDPcMeta?.alias || [],
    redirect: simulation_45pinelmeNadAxDPcMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/28/pages/simulateurs-immobilier/simulation-pinel.vue").then(m => m.default || m)
  },
  {
    name: simulation_45ptzzT8DMs1axkMeta?.name ?? "simulateurs-immobilier-simulation-ptz",
    path: simulation_45ptzzT8DMs1axkMeta?.path ?? "/simulateurs-immobilier/simulation-ptz",
    meta: simulation_45ptzzT8DMs1axkMeta || {},
    alias: simulation_45ptzzT8DMs1axkMeta?.alias || [],
    redirect: simulation_45ptzzT8DMs1axkMeta?.redirect,
    component: () => import("/var/www/nuxt-v3/prod/releases/28/pages/simulateurs-immobilier/simulation-ptz.vue").then(m => m.default || m)
  }
]